@use "sass:math";

@import "../../../../../node_modules/breakpoint-sass/stylesheets/_breakpoint";

// Rem converter mixin. Convert values to rem values.
// This mixin uses 16px in its calculation BUT, our default font-size value is 13px,
// see apps/hermes-web/src/styles.scss in body {}
// @property : CSS property for which to convert into rem values
// @values : Value list of that rem property
@mixin rem($property, $values) {
    $px-values: ();
    $rem-values: ();

    @each $value in $values {
        @if $value == 0 or type-of($value) != "number" {
            $px-values: append($px-values, $value);
            $rem-values: append($rem-values, $value);
        } @else {
            $px-values: append($px-values, $value);
            $rem-values: append($rem-values, math.div($value, 16) * 1rem);
        }
    }

    #{$property}: $rem-values;
}

@function ratiocalc($target, $container) {
    @return percentage(math.div($target, $container));
}

// Rem function, convert values only.
// This mixin uses 16px in its calculation BUT, our default font-size value is 13px,
// see apps/hermes-web/src/styles.scss in body {}
// Usage :
// padding: rem(0 20);
//
// @values : Value list of that rem property
@function rem($values...) {
    $rem-values: ();

    @each $value in $values {
        @if $value == 0 or type-of($value) != "number" {
            $rem-values: append($rem-values, $value);
        } @else {
            $rem-values: append($rem-values, math.div($value, 16) * 1rem);
        }
    }
    @return $rem-values;
}

// Underline
// @color : underline color
@mixin underline($color) {
    text-decoration: none;
    background-image: linear-gradient(
        rgba($color, 0.8) 50%,
        rgba($color, 0.8) 50%
    );
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 1px 1px;

    &:hover {
        background-image: none;
    }
}

@mixin underline-reset {
    background-image: none;
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 1px 1px;

    &:hover {
        background-image: none;
    }
}

@mixin underline-light {
    text-decoration: none;
    background-image: linear-gradient(
        rgba($color-primary-dark, 0.8) 50%,
        rgba($color-primary-dark, 0.8) 50%
    );
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 1px 1px;
    color: $color-primary-dark;

    &:hover {
        background-image: linear-gradient(
            rgba($color-primary-dark, 0) 50%,
            rgba($color-primary-dark, 0) 50%
        );
        color: $color-primary-dark;
    }
}

@mixin underline-border-bottom($color) {
    text-decoration: none;
    color: $color;
    padding-bottom: 4px;
    border-bottom: 1px solid $color;

    &:hover {
        border-bottom: none;
    }
}

// Custom focus
@mixin custom-focus($offset: 0) {
    outline: 2px solid $color-black;
    outline-offset: $offset;
}

// Custom focus error
@mixin custom-focus-error($offset: 0) {
    outline: 2px solid $color-error;
    outline-offset: $offset;
}

// Custom focus offset overwrite - to be used when default custom-focus outline with offset is truncated
@mixin custom-focus-offset-overwrite {
    outline-offset: -1px;
}

// Custom checkbox focus
@mixin custom-checkbox-focus {
    outline: 1px dotted $color-black;
    outline-offset: 1px;
}

// Input border
@mixin input-border {
    border: 1px solid $color-primary-light;

    &:hover {
        border-color: $color-primary-light;
    }
}

// Float clear
@mixin float-clear {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin clearfix() {
    &::before,
    &::after {
        content: " "; // 1
        display: table; // 2
    }

    &::after {
        clear: both;
    }
}

// @see https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#accessibility_concerns
// @$border-size : if there is a border (eg. on button). Only in rem css unit
@mixin line-height($border-size: 0) {
    line-height: calc(
        calc(#{$font-size-body-l} * 1.5) - calc(#{$border-size} * 2)
    );
}

// Prevent iOS from autozooming on inputs
// safari 10.1+
@mixin prevent-iOS-autozooming-input() {
    // Target Safari 10+
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            @include rem(font-size, $font-size-body-xl);
        }
    }
}

// LAYOUT TYPE for official templating (use in main conntainer)
// var SCSS to header/footer folowing layout type "fixe"
$layout-type-margin-fixe-mobile: 4px;
$layout-type-margin-fixe-desktop: 24px;

// The official Layout type "fixe" follow the same margins as header/footer and content of a page. But on mobile, header and footer have a little more margins
$margins-header-footer-on-mobile: $layout-type-margin-fixe-mobile + 11px;

@mixin layout-type-margin-adaptative {
    margin: 0 16px;

    // This breakpoint is not offical, it exists to trigger margin auto
    @include breakpoint(1290px) {
        max-width: 1258px;
        margin: 0 auto;
    }
}

@mixin layout-type-margin-fixe {
    margin: 0 $layout-type-margin-fixe-mobile;

    @include breakpoint($breakpoint-medium) {
        margin: 0 $layout-type-margin-fixe-desktop;
    }
}

@mixin layout-type-margin-full {
    max-width: $breakpoint-large;
    margin: 0 auto;
}

@mixin grid-container {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    column-gap: 16px;
}

// Locks the body scroll position, primarily on iOS devices, where 'overflow: hidden' alone doesn't prevent scrolling.
.fixed-with-offset {
    position: fixed;
    top: var(--scroll-y);
}

@import "settings";
