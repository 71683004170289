select {
    // avoid round corner on apple safari
    border-radius: 0;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;

    // for ie
    color: hsl(0deg 0% 100% / 0%);

    option {
        color: $color-black;
    }
}

//For IE hide default arrow from select
::-ms-expand {
    display: none;
}
