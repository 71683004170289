/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-disable color-no-hex */

@use "sass:map";
@import "fonts.map";

// When modify something here, generate new json with `npm run json-sass`

// Breakpoints

/**
 * @sass-export-section="breakpoints"
 */
$breakpoint-smallest: 320px;
$breakpoint-medium: 768px;
$breakpoint-large: 1920px;

// @end-sass-export-section

/**
 * @sass-export-section="fontLocales"
 */
$locales: "default", "ja", "ko", "zh-Hans", "zh-Hant";

// @end-sass-export-section

/**
 * Do not remove this until the bug is fixed or the library is changed for a better one!
 * This section is used only for the storybook and the fonts component. It is due to a lasting bug of the sass-export library used to export the sass variables:
 * When exporting map variables including string with comma, the internal method inspect of the converter mistakes the string for an array and throws an error
 * (https://github.com/plentycode/sass-export/pull/69)
 */

/**
 * @sass-export-section="fontMaps"
 */

$font-secondary-default: map.get($mapped-font-locale, "font-secondary-default");
$font-primary-default: map.get($mapped-font-locale, "font-primary-default");
$font-secondary-ja: map.get($mapped-font-locale, "font-secondary-ja");
$font-primary-ja: map.get($mapped-font-locale, "font-primary-ja");
$font-secondary-ko: map.get($mapped-font-locale, "font-secondary-ko");
$font-primary-ko: map.get($mapped-font-locale, "font-primary-ko");
$font-secondary-zh-Hans: map.get($mapped-font-locale, "font-secondary-zh-Hans");
$font-primary-zh-Hans: map.get($mapped-font-locale, "font-primary-zh-Hans");
$font-secondary-zh-Hant: map.get($mapped-font-locale, "font-secondary-zh-Hant");
$font-primary-zh-Hant: map.get($mapped-font-locale, "font-primary-zh-Hant");

// @end-sass-export-section

// Base font size

/**
 * @sass-export-section="fonts"
 */

// Max size product image
$product-image-max-size: 850px;

// sticked right-column width on product page
$right-column-width: 375px;

// Font family
$font-secondary: var(--font-secondary);
$font-primary: var(--font-primary);

// Perso fonts
$font-filosofia: var(--font-filosofia);
$font-akkurat: var(--font-akkurat);
$font-jungle-love: var(--font-jungle-love);
$font-brides-de-gala: var(--font-brides-de-gala);
$font-mors-a-jouet: var(--font-mors-a-jouet);

// Font size - new font rules
$font-size-heading-xl: var(--font-size-heading-xl);
$font-size-heading-l: var(--font-size-heading-l);
$font-size-heading-m: var(--font-size-heading-m);
$font-size-heading-default: var(--font-size-heading-default);
$font-size-heading-s: var(--font-size-heading-s);
$font-size-heading-xs: var(--font-size-heading-xs);
$font-size-body-xl: var(--font-size-body-xl);
$font-size-body-l: var(--font-size-body-l);
$font-size-body-m: var(--font-size-body-m);
$font-size-body-default: var(--font-size-body-default);
$font-size-body-s: var(--font-size-body-s);
$font-size-body-xs: var(--font-size-body-xs);

// Font weight
$font-weight-title: var(--font-weight-title);
$font-weight-title-bold: var(--font-weight-title-bold);
$font-weight-cta: var(--font-weight-cta);
$font-weight-paragraph: var(--font-weight-paragraph);
$font-weight-paragraph-bold: var(--font-weight-paragraph-bold);

// @end-sass-export-section

// Variables pour les letter spacing
$letter-spacing-one: 1px;
$letter-spacing-half: 0.5px;
$no-letter-spacing: 0;

// Colors

/**
 * @sass-export-section="miscellanousColors"
 */
$color-white: #fff;
$color-black: #000;
$color-grey: #919191;
$color-error: #9d2a1e;
$color-validation: #34784a;

// @end-sass-export-section

/**
 * @sass-export-section="primaryColors"
 */
$color-primary-dark: #444;
$color-primary: #696969;
$color-primary-light: #e5e5e5;
$color-primary-extra-light: #f5f5f5;

// @end-sass-export-section

/**
 * @sass-export-section="secondaryColors"
 */
$color-secondary-dark: #e2d8ce;
$color-secondary: #f6f1eb;
$color-secondary-light: #fffcf7;
$color-secondary-extra-light: #fcf7f1;

// @end-sass-export-section

/**
 * @sass-export-section="layouts"
 */

// Global layout
$z-header: 300;
$z-baidu-suggestion: 90;
$z-baidu-suggestion-tray: 2010;

// Dynamic top value to apply to be right beside the header menu.
:root {
    --header-menu-top: 58px;
}

// @end-sass-export-section
