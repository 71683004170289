@import "settings";

body {
    color: $color-primary-dark;
    font-family: $font-primary;
}

// Variables pour les polices et poids
$font-weight-regular: normal;
$font-weight-bold: bold;
$font-weight-light: 300;

// MIXIN (To be used in .scss files)
// Heading
@mixin heading-1 {
    font-size: $font-size-heading-m;
    line-height: 34px;
    font-weight: $font-weight-regular;
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
}
@mixin heading-1-display-light {
    font-size: $font-size-heading-xl;
    line-height: 38px;
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    letter-spacing: $no-letter-spacing;
}

@mixin heading-2 {
    font-size: $font-size-heading-s;
    line-height: 28px;
    font-weight: $font-weight-regular;
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
}
@mixin heading-2-display-light {
    font-size: $font-size-heading-l;
    line-height: 34px;
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    letter-spacing: $no-letter-spacing;
}

@mixin heading-3 {
    font-size: $font-size-heading-xs;
    line-height: 26px;
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
}
@mixin heading-3-display-light {
    font-size: $font-size-heading-default;
    line-height: 28px;
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
}

@mixin heading-4 {
    font-size: $font-size-body-xl;
    line-height: 24px;
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
}
@mixin heading-4-display-light {
    font-size: $font-size-body-xl;
    line-height: 20px;
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
}

@mixin heading-5 {
    font-size: $font-size-body-l;
    line-height: 22px;
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
}

@mixin heading-5-display-light {
    font-size: $font-size-body-l;
    line-height: 22px;
    font-family: $font-secondary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    letter-spacing: $no-letter-spacing;
}

@mixin heading-6 {
    font-size: $font-size-body-m;
    line-height: 20px;
}

// Paragraphs
@mixin paragraph-medium {
    font-size: $font-size-body-l;
    line-height: 22px;
    font-weight: $font-weight-regular;
    letter-spacing: $no-letter-spacing;
}
@mixin paragraph-small {
    font-size: $font-size-body-m;
    line-height: 20px;
    font-weight: $font-weight-regular;
    letter-spacing: $no-letter-spacing;
}
@mixin paragraph-xsmall {
    font-size: $font-size-body-default;
    line-height: 18px;
    font-weight: $font-weight-regular;
    letter-spacing: $no-letter-spacing;
}

// Labels
@mixin label-large-uppercase {
    font-size: $font-size-body-default;
    line-height: 16px;
    letter-spacing: $letter-spacing-one;
    text-transform: uppercase;
}
@mixin label-medium-bold-uppercase {
    font-size: $font-size-body-s;
    line-height: 14px;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-one;
    text-transform: uppercase;
}
@mixin label-small-bold-uppercase {
    font-size: $font-size-body-xs;
    line-height: 10px;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-one;
    text-transform: uppercase;
}

// Others
@mixin regular-uppercase {
    text-transform: uppercase;
    letter-spacing: $letter-spacing-half;
}

// CSS class to be used directly in html files

h1,
.heading-1 {
    @include heading-1;

    &.display-light {
        @include heading-1-display-light;
    }
}

h2,
.heading-2 {
    @include heading-2;

    &.display-light {
        @include heading-2-display-light;
    }

    &.regular-uppercase {
        @include regular-uppercase;
    }
}

h3,
.heading-3 {
    @include heading-3;

    &.display-light {
        @include heading-3-display-light;
    }
}

h4,
.heading-4 {
    @include heading-4;

    &.display-light {
        @include heading-4-display-light;
    }

    &.regular-uppercase {
        @include regular-uppercase;
    }
}

h5,
.heading-5 {
    @include heading-5;

    &.regular-uppercase {
        @include regular-uppercase;
    }
}

h6,
.heading-6 {
    @include heading-6;

    &.regular-uppercase {
        @include regular-uppercase;
    }
}

.paragraph {
    &-medium {
        @include paragraph-medium;
    }

    &-small {
        @include paragraph-small;
    }

    &-xsmall {
        @include paragraph-xsmall;
    }
}

.label {
    &-large-uppercase {
        @include label-large-uppercase;
    }

    &-medium-bold-uppercase {
        @include label-medium-bold-uppercase;
    }

    &-small-bold-uppercase {
        @include label-small-bold-uppercase;
    }
}

.bold {
    font-weight: $font-weight-bold;
}

.underline {
    text-decoration: underline;
}

p {
    // style paragraph medium nouvelle typo
    font-size: $font-size-body-l;
    line-height: 22px;
    letter-spacing: $no-letter-spacing;
    margin: 0;
}

a {
    color: $color-primary-dark;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

code {
    font-family: $font-primary;
    color: $color-black;
    background-color: $color-primary-extra-light;
    margin: 8px 6px;
    font-style: italic;
}

input,
textarea,
select,
button {
    font-family: $font-primary;
}
