@import "tools";
// The host element of the ripple directive should always have a position of "absolute" or
// "relative" so that the ripples inside are correctly positioned relatively to the container.
.h-ripple {
    overflow: hidden;
    position: relative;
}

.h-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
    background-color: rgba($color-black, 0.5);
    opacity: 0.1;
}
