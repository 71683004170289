@import "tools";

a,
button,
input,
select,
textarea,
h2,
h3,
[tabindex="0"] {
    &:focus {
        outline: none;
    }

    &::-moz-focus-inner {
        border: none;
    }
}

.keyboard-navigation {
    a,
    input,
    select,
    button,
    textarea,
    [tabindex="0"] {
        &:focus {
            @include custom-focus;
        }
    }

    h-form-field input {
        outline: none;

        &:focus {
            outline: none;
        }
    }

    [focus="true"] {
        @include custom-focus;
    }

    // focus on cart-page inputs
    input:focus + .checkmark {
        @include custom-focus(3px);
    }

    // Exception
    .cart-page {
        h4,
        h5,
        [tabindex="-1"] {
            &:focus {
                @include custom-focus;
            }
        }
    }
}
