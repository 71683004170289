@import "tools";

.skip-links {
    @include rem(font-size, $font-size-body-m);

    color: $color-primary-dark;
    font-family: $font-primary;
    text-decoration: none;
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;

    &:focus {
        position: static;
        width: auto;
        height: auto;
        clip: auto;
        overflow: visible;
    }
}
