@import "settings";

// styles for drupal admin buttons so that they don't mess with angular styles
#page.page-wrapper > .tabs {
    position: absolute;
    z-index: 9002;
    left: 6%;

    > .tabs-primary {
        display: flex;
        flex-direction: row;

        > .tabs {
            flex: 0 0 auto;
        }

        > .tabs-primary__tab {
            background: linear-gradient(
                180deg,
                $color-primary-dark,
                $color-black
            );
            border-radius: 2em;
            margin-right: 1em;

            a {
                color: $color-white;
                padding: 5px 10px;
                text-transform: uppercase;
                text-decoration: none;
            }

            &:hover,
            &:focus {
                background: linear-gradient(
                    0deg,
                    $color-primary-dark,
                    $color-black
                );
                text-decoration: underline;
            }
        }
    }
}
