@use "sass:math";

@import "normalize/import-now";
@import "tools";
@import "fonts";

@import "~@angular/cdk/overlay-prebuilt.css";
@import "~@angular/cdk/a11y-prebuilt.css";

// TODO : Remove h-root rule and generic.reset_cms_rules import when the pages will be full Angular.
// Add here your DOM element outside <h-root> to inherit all sass imports :
// - .sb-show-main : storybook
// - .modal-service : cdk-dialog
h-root,
.sb-show-main,
.modal-service {
    @import "generic.reset_cms_rules";
    @import "generic.base_layout";
    @import "elements.typography";
    @import "elements.list";
    @import "elements.form";
    @import "elements.checkbox";
    @import "elements.select";
    @import "objects.button";
    @import "objects.focus";
    @import "objects.sr_only";
    @import "objects.ripple";
    @import "objects.animations";
    @import "objects.skip-links";
    @import "objects_editorial.typography";
}
@import "elements.drupal";
@import "objects.js_activation";

@font-face {
    font-family: "Manrope";
    src: url("./assets/fonts/manrope-regular.woff2") format("woff2");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("./assets/fonts/manrope-bold.woff2") format("woff2");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Overpass Mono";
    src: url("./assets/fonts/overpassmono-light.woff2") format("woff2");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

/** This body is important, it set rule for all RDR
  * eg. font size to 75% transform our 16px default value for font-size to 12px !
  */
body {
    margin: 0;
    height: 100vh;
    width: 100%;
    background-color: $color-secondary;
    font-family: $font-primary;
    color: $color-primary-dark;
    /* stylelint-disable-next-line */
    font-size: 75%;
}

// Overrides Drupal CSS for trays
.tray-open {
    margin-right: 0;
}

// Remove storybook background color transition for contrast a11y test
// See : https://github.com/storybookjs/storybook/issues/25257
.sb-show-main {
    transition: none !important;
}
