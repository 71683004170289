@import "tools";

button {
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    color: $color-primary-dark;
    display: inline-block;
}
