@use "sass:map";

@import "tools";
@import "fonts.map";

@mixin set-fonts($locale) {
    // latin equivalent : Overpass Mono
    --font-secondary: #{map.get(
            $mapped-font-locale,
            "font-secondary-#{$locale}"
        )};

    // latin equivalent : Manrope
    --font-primary: #{map.get($mapped-font-locale, "font-primary-#{$locale}")};
}

@mixin set-sizes($locale) {
    @include rem(
        --font-size-heading-xl,
        map.get($mapped-size-locale, "font-size-heading-xl-#{$locale}")
    );
    @include rem(
        --font-size-heading-l,
        map.get($mapped-size-locale, "font-size-heading-l-#{$locale}")
    );
    @include rem(
        --font-size-heading-m,
        map.get($mapped-size-locale, "font-size-heading-m-#{$locale}")
    );
    @include rem(
        --font-size-heading-default,
        map.get($mapped-size-locale, "font-size-heading-default-#{$locale}")
    );
    @include rem(
        --font-size-heading-s,
        map.get($mapped-size-locale, "font-size-heading-s-#{$locale}")
    );
    @include rem(
        --font-size-heading-xs,
        map.get($mapped-size-locale, "font-size-heading-xs-#{$locale}")
    );
    @include rem(
        --font-size-body-xl,
        map.get($mapped-size-locale, "font-size-body-xl-#{$locale}")
    );
    @include rem(
        --font-size-body-l,
        map.get($mapped-size-locale, "font-size-body-l-#{$locale}")
    );
    @include rem(
        --font-size-body-m,
        map.get($mapped-size-locale, "font-size-body-m-#{$locale}")
    );
    @include rem(
        --font-size-body-default,
        map.get($mapped-size-locale, "font-size-body-default-#{$locale}")
    );
    @include rem(
        --font-size-body-s,
        map.get($mapped-size-locale, "font-size-body-s-#{$locale}")
    );
    @include rem(
        --font-size-body-xs,
        map.get($mapped-size-locale, "font-size-body-xs-#{$locale}")
    );
}

:root {
    @include set-fonts("default");
    @include set-sizes("default");

    // Perso fonts
    --font-filosofia: "Filosofia", serif;
    --font-akkurat: "Akkurat", serif;
    --font-jungle-love: "Jungle-Regular", serif;
    --font-brides-de-gala: "BridesdeGala-Regular", serif;
    --font-mors-a-jouet: "Mors-Regular-Auto", serif;

    // TODO : somes exceptions, delete soon #design-system
    --font-weight-title: 400;
    --font-weight-title-bold: 600;
    --font-weight-cta: 500;
    --font-weight-paragraph: 400;
    --font-weight-paragraph-bold: 600;
}

@each $locale in $locales {
    @if $locale != "default" {
        :root[lang^="#{$locale}"] {
            @include set-fonts($locale);
            @include set-sizes($locale);
        }
    }
}
