* {
    box-sizing: border-box;
}

body {
    background-color: $color-secondary;
}

.main-container {
    @include rem(margin, 0 15);
    @include rem(font-weight, normal);
    @include breakpoint($breakpoint-medium) {
        max-width: 1290px;
        margin: auto;
        @include rem(padding, 0 15);
    }
}

.mobile-only {
    @include breakpoint($breakpoint-medium) {
        display: none;
    }
}

.desktop-only {
    @media (max-width: $breakpoint-medium - 1px) {
        display: none;
    }
}
