// TODO : To remove when the pages will be full Angular.
// Reset the rules from cms that impact the Angular part.

@import "tools";

.product-item,
.form-sort,
.filter-options ul {
    padding: 0;
}

.form-item-select {
    height: auto;
}

.has-sub-item[aria-expanded="false"]::after,
.has-sub-item[aria-expanded="true"]::after {
    content: none;
}
